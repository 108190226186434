<template>
  <Modal class="file-options" ref="modal" :settings="{ opened: true, outClose: true }" @close="modalClose" @open="modalOpen">
    <div class="file-options-logo" slot="header">
      <p class="third-font">
        <iconic name="mf_lock_flat" />
      </p>
    </div>
    <div class="file-options-content">
      <ListItem text="Editar" icon="pencil" class="file-options-item" @click="editFile" />
      <ListItem text="Borrar" icon="trash" class="file-options-item" @click="deleteFile" />
    </div>
  </Modal>
</template>

<script>
export default {
  data: function() {
    return {
      animation: null,
      duration: 250,
      eventX: 0,
      eventY: 0,
    };
  },
  components: {},
  computed: {
    file: function() {
      return this.$store.state.user.fileMenu;
    },
  },
  methods: {
    modalClose: function() {
      this.$store.state.user.fileMenu = null;
    },
    closeNewsVisor: function({ removeQuery }) {
      this.$store.state.news.NewsVisor = null;
      if (removeQuery) {
        this.$router.push({ query: { news: undefined } }).catch(() => {});
      }
    },
    editFile: function() {
      this.$store.state.user.fileEdit = this.file.file;
      this.modalClose();
    },
    deleteFile: function() {
      this.$store.state.user.fileDelete = this.file.file;
      this.modalClose();
    },
    toggleVisiblitity: function() {
      const newStatus = this.isNewsArchive(this.news) ? false : true;
      this.onToggle(newStatus, this.news._id);
      this.modalClose();
      this.closeNewsVisor({ removeQuery: true });
    },
    modalOpen: async function() {
      await this.sleep(100);
      const modalCard = this.$refs.modal.$el.querySelector(".modal-card");
      if (modalCard) {
        const cardWidth = modalCard.offsetWidth;
        const cardHeight = modalCard.offsetHeight;
        modalCard.style.left = `${this.eventX - cardWidth}px`;
        modalCard.style.opacity = 1;

        const windowsHeight = window.innerHeight || window.innerHeight;
        const totalTop = this.eventY + cardHeight;

        if (totalTop > windowsHeight) {
          modalCard.style.top = `${this.eventY - cardHeight}px`;
        } else {
          modalCard.style.top = `${this.eventY}px`;
        }
      }
    },
  },
  mounted: function() {
    this.eventX = this.file.event.clientX;
    this.eventY = this.file.event.clientY;
  },
};
</script>

<style lang="scss">
.file-options {
  cursor: inherit;
  &-logo {
    padding: $mpadding;
    color: $primary-color;
    font-size: 120%;
    font-weight: bold;
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .modal-card {
    position: fixed;
    overflow: hidden;
    opacity: 0;
    @include Transition(0.25s);
    @include Animation(slide-bottom, 0.25s);
    box-shadow: 0 0 $mpadding * 1 rgba(0, 0, 0, 0.616);
    width: auto;
    &-content {
      padding: 0 0 0 0 !important;
    }
  }
  &-item {
    border-top: solid 1px $alto;
    padding: $mpadding/1.5 $mpadding !important;
    .iconic {
      font-size: 75%;
    }
    &:hover {
      .iconic {
        color: $primary-color;
      }
    }
  }
}
</style>
